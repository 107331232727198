import { TokenlonEnv } from '~/constants/env'
import { Chain, ChainLite } from '~/typings/tokenlon'
import LogoETH from '~/assets/images/chain-ethereum.svg'
import LogoARB from '~/assets/images/chain-arbitrum.svg'
import LogoBase from '~/assets/images/chain-base.svg'
import LogoBSC from '~/assets/images/chain-bsc.svg'
import LogoPolygon from '~/assets/images/chain-polygon.svg'
import LogoOptimism from '~/assets/images/chain-optimism.svg'
import LogoZksync from '~/assets/images/chain-zksync.svg'
import TokenLogoPol from '~/assets/images/token-pol.svg'
import TokenLogoBnb from '~/assets/images/token-bnb.svg'
import { LOCALES } from '~/locales'
import type { RetryOptions } from '~/utils/retry'

const DEFAULT_RETRY_OPTIONS: RetryOptions = {
  n: 10,
  minWait: 250,
  maxWait: 1000,
}

export const Mainnet: Chain = {
  chainId: 1,
  chainName: 'Ethereum',
  logoUrl: LogoETH,
  nativeSymbol: 'ETH',
  rpcUrl: 'https://eth-mainnet.token.im',
  publicRpcUrl: 'https://eth.llamarpc.com',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
  lonAddress: '0x0000000000095413afC295d19EDeb1Ad7B71c952',
  xLonAddress: '0xf88506b0f1d30056b9e5580668d5875b9cd30f23',
  rewardDistributorAddress: '0xbF1C2c17CC77e7Dec3466B96F46f93c09f02aB07',
  explorer: 'https://etherscan.io',
  getExplorerAddressLink: (address: string, locale: LOCALES) =>
    `https://${
      locale === LOCALES.ZH ? 'cn.etherscan.com' : 'etherscan.io'
    }/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string, locale: LOCALES) =>
    `https://${
      locale === LOCALES.ZH ? 'cn.etherscan.com' : 'etherscan.io'
    }/tx/${transactionHash}`,
  getExplorerTokenLink: (address: string, locale: LOCALES) =>
    `https://${
      locale === LOCALES.ZH ? 'cn.etherscan.com' : 'etherscan.io'
    }/token/${address}`,
  matchedEnv: [TokenlonEnv.production, TokenlonEnv.staging],
}

export const Goerli: Chain = {
  chainId: 5,
  chainName: 'Goerli',
  nativeSymbol: 'ETH',
  logoUrl: LogoETH,
  rpcUrl: 'https://eth-testnet.imdev.works',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
  lonAddress: '0x6dA0e6ABd44175f50C563cd8b860DD988A7C3433',
  xLonAddress: '0x235d9B4249E9C9D705fAC6E98F7D21E58091220A',
  rewardDistributorAddress: '0x02F929c7C837839a0D6B44B1d6Dbce64Fa87C9f3',
  explorer: 'https://goerli.etherscan.io',
  getExplorerAddressLink: (address: string) =>
    `https://goerli.etherscan.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://goerli.etherscan.io/tx/${transactionHash}`,
  getExplorerTokenLink: (address: string) =>
    `https://goerli.etherscan.io/token/${address}`,
  matchedEnv: [TokenlonEnv.local, TokenlonEnv.development],
}

const SepoliaExplorer = 'https://sepolia.etherscan.io'
export const Sepolia: Chain = {
  chainId: 11155111,
  chainName: 'Sepolia',
  nativeSymbol: 'ETH',
  logoUrl: LogoETH,
  rpcUrl: 'https://sepolia-testnet.imdev.works',
  publicRpcUrl: 'https://rpc.sepolia.org',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0xD7F33bCdb21b359c8ee6F0251d30E94832baAd07',
  lonAddress: '0x6C1851b852F05bdc7c0BE1A088532E4999fD94Fa',
  explorer: SepoliaExplorer,
  getExplorerAddressLink: (address: string) =>
    `${SepoliaExplorer}/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `${SepoliaExplorer}/tx/${transactionHash}`,
  getExplorerTokenLink: (address: string) =>
    `${SepoliaExplorer}/token/${address}`,
  matchedEnv: [TokenlonEnv.local, TokenlonEnv.development],
}

export const ArbitrumOne: Chain = {
  chainId: 42161,
  chainName: 'Arbitrum',
  nativeSymbol: 'ETH',
  // rpcUrl: 'https://arbitrum-mainnet.token.im',
  rpcUrl: 'https://arb1.arbitrum.io/rpc',
  publicRpcUrl: 'https://arb1.arbitrum.io/rpc',
  logoUrl: LogoARB,
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
  lonAddress: '0x55678cd083fcDC2947a0Df635c93C838C89454A3',
  explorer: 'https://arbiscan.io',
  getExplorerAddressLink: (address: string) =>
    `https://arbiscan.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://arbiscan.io/tx/${transactionHash}`,
  getExplorerTokenLink: (address: string) =>
    `https://arbiscan.io/token/${address}`,
  matchedEnv: [TokenlonEnv.staging, TokenlonEnv.production],
  pendingTransactionsRetryOptions: DEFAULT_RETRY_OPTIONS,
}

export const ArbitrumRinkeby: Chain = {
  chainId: 421611,
  chainName: 'Arbitrum Rinkeby',
  nativeSymbol: 'ARETH',
  rpcUrl: 'https://rinkeby.arbitrum.io/rpc',
  // rpcUrl: 'https://arbitrum-testnet.imdev.works',
  logoUrl: LogoARB,
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
  lonAddress: '0x3828E007B7413Be83550AB520f706cC1773464DB',
  explorer: 'https://testnet.arbitrum.io',
  getExplorerAddressLink: (address: string) =>
    `https://testnet.arbiscan.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://testnet.arbiscan.io/tx/${transactionHash}`,
  getExplorerTokenLink: (address: string) =>
    `https://testnet.arbiscan.io/tx/${address}`,
  matchedEnv: [TokenlonEnv.local, TokenlonEnv.development],
  pendingTransactionsRetryOptions: DEFAULT_RETRY_OPTIONS,
}

export const WCMainnet = {
  chainId: 1,
  rpcUrl: 'https://eth-mainnet.token.im',
}

export const WCSepolia = {
  chainId: 11155111,
  rpcUrl: 'https://rpc.sepolia.org',
}

export const WCArbitrumOne = {
  chainId: 42161,
  // rpcUrl: 'https://arbitrum-mainnet.token.im',
  rpcUrl: 'https://arb1.arbitrum.io/rpc',
}

export const WCArbitrumRinkeby = {
  chainId: 421611,
  rpcUrl: 'https://rinkeby.arbitrum.io/rpc',
}

export const Optimism: Chain = {
  chainId: 10,
  chainName: 'Optimism',
  logoUrl: LogoOptimism,
  nativeSymbol: 'ETH',
  rpcUrl: 'https://mainnet.optimism.io',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
  explorer: 'https://optimistic.etherscan.io',
  getExplorerAddressLink: (address: string) =>
    `https://optimistic.etherscan.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://optimistic.etherscan.io/tx/${transactionHash}`,
  getExplorerTokenLink: (address: string) =>
    `https://optimistic.etherscan.io/token/${address}`,
  matchedEnv: [TokenlonEnv.staging, TokenlonEnv.production],
  pendingTransactionsRetryOptions: DEFAULT_RETRY_OPTIONS,
}

export const BSC: Chain = {
  chainId: 56,
  chainName: 'BNB Smart Chain',
  logoUrl: LogoBSC,
  nativeSymbol: 'BNB',
  nativeCurrencyLogo: TokenLogoBnb,
  rpcUrl: 'https://1rpc.io/bnb',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x963Df249eD09c358A4819E39d9Cd5736c3087184',
  explorer: 'https://bscscan.com',
  getExplorerAddressLink: (address: string) =>
    `https://bscscan.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://bscscan.com/tx/${transactionHash}`,
  getExplorerTokenLink: (address: string) =>
    `https://bscscan.com/token/${address}`,
  matchedEnv: [TokenlonEnv.staging, TokenlonEnv.production],
  pendingTransactionsRetryOptions: undefined,
}

export const Polygon: Chain = {
  chainId: 137,
  chainName: 'Polygon Mainnet',
  logoUrl: LogoPolygon,
  nativeSymbol: 'POL',
  nativeCurrencyLogo: TokenLogoPol,
  rpcUrl: 'https://1rpc.io/matic',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
  explorer: 'https://polygonscan.com',
  getExplorerAddressLink: (address: string) =>
    `https://polygonscan.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://polygonscan.com/tx/${transactionHash}`,
  getExplorerTokenLink: (address: string) =>
    `https://polygonscan.com/token/${address}`,
  matchedEnv: [TokenlonEnv.staging, TokenlonEnv.production],
  pendingTransactionsRetryOptions: undefined,
}

export const zkSync: Chain = {
  chainId: 324,
  chainName: 'zkSync Era',
  logoUrl: LogoZksync,
  nativeSymbol: 'ETH',
  rpcUrl: 'https://mainnet.era.zksync.io',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x0c68a7C72f074d1c45C16d41fa74eEbC6D16a65C',
  explorer: 'https://explorer.zksync.io',
  getExplorerAddressLink: (address: string) =>
    `https://explorer.zksync.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://explorer.zksync.io/tx/${transactionHash}`,
  getExplorerTokenLink: (address: string) =>
    `https://explorer.zksync.io/token/${address}`,
  matchedEnv: [TokenlonEnv.staging, TokenlonEnv.production],
  pendingTransactionsRetryOptions: undefined,
}

export const Base: Chain = {
  chainId: 8453,
  chainName: 'Base',
  logoUrl: LogoBase,
  nativeSymbol: 'ETH',
  rpcUrl: 'https://mainnet.base.org',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x091e99cb1C49331a94dD62755D168E941AbD0693',
  explorer: 'https://basescan.org',
  getExplorerAddressLink: (address: string) =>
    `https://basescan.org/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://basescan.org/tx/${transactionHash}`,
  getExplorerTokenLink: (address: string) =>
    `https://basescan.org/token/${address}`,
  matchedEnv: [TokenlonEnv.staging, TokenlonEnv.production],
  pendingTransactionsRetryOptions: DEFAULT_RETRY_OPTIONS,
}

export const Avalanche: ChainLite = {
  chainId: 43114,
  chainName: 'Avalanche',
}

export const Cronos: ChainLite = {
  chainId: 25,
  chainName: 'Cronos',
}

export const Fantom: ChainLite = {
  chainId: 250,
  chainName: 'Fantom',
}

export const Klaytn: ChainLite = {
  chainId: 8217,
  chainName: 'Klaytn',
}

export const Moonbeam: ChainLite = {
  chainId: 1284,
  chainName: 'Moonbeam',
}

export const Gnosis: ChainLite = {
  chainId: 100,
  chainName: 'Gnosis',
}

export const Aurora: ChainLite = {
  chainId: 1313161554,
  chainName: 'Aurora',
}

export default {
  Mainnet,
  Sepolia,
  ArbitrumOne,
  ArbitrumRinkeby,
  Base,
  BSC,
  Optimism,
  Polygon,
  zkSync,
}
